import React, { useState, useEffect } from "react";
import LoadingIcon from "./LoadingIcon";
import { Helmet } from "react-helmet";

export default function GalleryPage(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [images, setImages] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        props.client.product.fetchByHandle("1-Galerie").then((product) => {
            setIsLoading(false);
            setImages(product.images);
        }).catch(err => {
            console.error(err);
            setIsLoading(false);
        });
    }, [props.client]);

    return (
        <div className="container pageContent">
            <Helmet title="Galerie" />
            <h1>Galerie</h1>
            <LoadingIcon isLoading={isLoading} />
            <div className="albumUploadedFiles card-columns gallery">
                {
                    images.map(image => (
                        <div key={image.id} className="card">
                            <img className="img-fluid" alt={image.altText} src={props.client.image.helpers.imageForSize(image, { maxWidth: 550, maxHeight: 2000 })} />
                        </div>
                    ))
                }
            </div>
            {!isLoading && images.length === 0 && (
                <p className="alert alert-info">Il n'y a aucune photo pour l'instant.</p>
            )}
        </div>
    );
}
export const WebsiteUrl = "https://confectionsmoufette.com";

export const shopifyClientConfig = {
  storefrontAccessToken: "f15150f26aa51a604d02c93626d341cb",
  domain: "confectionsmoufette.myshopify.com",
};

export const googleMapApiKey = "AIzaSyDK7fJ_Y9ru_Aj0MuGq5ngCa9PYBGQrEN8";

export const facebookPageUrl = "https://www.facebook.com/confectionsmoufette/";

export const FavoriteTagName = "coup de coeur";

export const subCategories = {
  couches: [
    {
      title: "Taille unique",
      url: "/categorie-produit/taille-unique",
    },
    {
      title: "Taille nouveau-né",
      url: "/categorie-produit/nouveau-ne",
    },
    {
      title: "Couches plates",
      url: "/categorie-produit/couches-plates",
    },
    {
      title: "Feuillets lavables",
      url: "/categorie-produit/feuillets-lavables",
    },
    {
      title: "Wetbags",
      url: "/categorie-produit/wetbags",
    },
    {
      title: "Brise de séchage",
      url: "/categorie-produit/brise-de-sechage",
    },
    {
      title: "Inserts absorbants",
      url: "/categorie-produit/inserts-absorbants",
    },
    {
      title: "Garantie",
      url: "/garantie/",
    },
  ],
  "pour-maman": [
    {
      title: "Serviette hygiénique lavables",
      url: "/categorie-produit/serviette-hygienique-lavables",
    },
    {
      title: "Vêtements",
      url: "/categorie-produit/vetements",
    },
    {
      title: "Chandelles",
      url: "/categorie-produit/chandelles",
    },
    {
      title: "Compresses d’allaitement",
      url: "/categorie-produit/compresses-d-allaitement",
    },
    {
      title: "Pour le bain",
      url: "/categorie-produit/pour-le-bain",
    },
    {
      title: "Soins des cheveux",
      url: "/categorie-produit/soins-des-cheveux",
    },
    {
      title: "Ensemble cadeau",
      url: "/categorie-produit/ensemble-cadeau",
    },
  ],
  "pour-bebe-et-enfant": [
    {
      title: "Bonnets et tuques",
      url: "/categorie-produit/bonnets-et-tuques",
    },
    {
      title: "Vêtements",
      url: "/categorie-produit/vetements-pour-bebe-et-enfant",
    },
    {
      title: "Album photos en tissu",
      url: "/produit/album-photo-lavable",
    },
    {
      title: "Divertissement",
      url: "/categorie-produit/divertissement",
    },
    {
      title: "Apprentissage",
      url: "/categorie-produit/apprentissage",
    },
    {
      title: "Changement de couches",
      url: "/categorie-produit/changement-de-couches",
    },
    {
      title: "Pour le bain",
      url: "/categorie-produit/lheure-du-bain",
    },
  ],
  "pour-la-maison": [
    {
      title: "Mouchoirs lavables",
      url: "/categorie-produit/mouchoirs-lavables",
    },
    {
      title: "Produits ménager",
      url: "/categorie-produit/produits-menager",
    },
  ],
  "pour-papa": [
    {
      title: "Chandelles",
      url: "/categorie-produit/chandelles",
    },
    {
      title: "Soins du corps",
      url: "/categorie-produit/soins-du-corps-homme",
    },
  ],
};

export const albumProductHandle = "album-photo-lavable";

export const wordpressPublicApiBaseUrl =
  "https://public-api.wordpress.com/rest/v1/sites/confectionsmoufette.wordpress.com";

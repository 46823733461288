import React, { useState } from "react";
import { FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import CartLineItem from "./CartLineItem";
import LoadingIcon from "./LoadingIcon";
import { Helmet } from "react-helmet";
import * as classNames from "classnames";
import CartDiscountRow from "./CartDiscountRow";
import { hasGlobalDiscountRate } from "../PriceHelper";

export default function Cart(props) {
    let items = [];
    let [editingCoupon, setEditingCoupon] = useState("");
    let [couponWarning, setCouponWarning] = useState(null);

    if (props.checkout) {
        items = props.checkout.lineItems;
    }

    function applyCoupon(ev) {
        ev.preventDefault();

        setCouponWarning(null);
        props.applyCoupon(editingCoupon).then(discounts => {
            if (discounts.some(x => x.code.toLowerCase() === editingCoupon.toLowerCase())) {
                setEditingCoupon("");
            } else {
                setCouponWarning("Coupon invalide");
            }
        })
    }

    const hasAutomaticDiscount = props.checkout?.discountApplications?.some(x => x.type.name === "AutomaticDiscountApplication");

    return (
        <div className="container pageContent">
            <Helmet title="Panier" />
            {items.length > 0 && (
                <table className="table mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Produit</th>
                            <th scope="col">Prix</th>
                            <th scope="col">Quantité</th>
                            <th scope="col">Sous-total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.filter(i => i.variant).map((item, i) => (
                            <CartLineItem key={item.id + i} test={i} item={item} removeItemFromCart={props.removeItemFromCart} updateItemQuantity={props.updateItemQuantity} client={props.client} />
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={5} className="text-right">

                                {!props.isCheckoutLoading && (
                                    <>
                                        {props.checkout.discountApplications.map(discount => (
                                            <CartDiscountRow key={discount.code || discount.title}
                                                code={discount.code || discount.title}
                                                isAutomatic={!discount.code}
                                                percentage={discount.value.percentage}
                                                amount={discount.value.amount}
                                                removeCoupon={props.removeCoupon} />
                                        ))}
                                        Sous-total: <FormattedNumber value={props.checkout.subtotalPrice} style="currency" currency={"CAD"} />
                                    </>
                                )}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            )}

            <LoadingIcon className="mb-2" isLoading={!props.isCheckoutLoaded} />
            {items.length === 0 && props.isCheckoutLoaded && (
                <div className="alert alert-info">Votre panier est actuellement vide.</div>
            )}

            <div className="row">
                <div className="col-5">
                    {!hasGlobalDiscountRate && !hasAutomaticDiscount && (
                        <form onSubmit={applyCoupon} className="form-inline">
                            <label htmlFor="couponTextbox">Coupon:</label>
                            <div className="input-group">
                                <input id="couponTextbox" disabled={props.isCheckoutLoading} type="text" className={classNames("form-control couponTextbox ml-1", { "is-invalid": couponWarning })} value={editingCoupon} onChange={ev => setEditingCoupon(ev.target.value)} />
                                <div className="input-group-append">
                                    <button type="submit" disabled={props.isCheckoutLoading || !editingCoupon} className="btn btn-default">Appliquer</button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
                <div className="col-7">
                    {items.length === 0 ? (
                        <Link to="/" className="btn btn-primary">Retour à la boutique</Link>
                    ) : (
                            <div className="text-right">
                                <p>Les taxes et frais de livraison seront calculés à la prochaine étape.</p>
                                <LoadingIcon isLoading={props.isCheckoutLoading} />
                                {!props.isCheckoutLoading && <a href={props.checkout.webUrl} className="btn btn-primary">Valider la commande</a>}
                            </div>
                        )}
                </div>
            </div>
        </div>
    );
}
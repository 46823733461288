import React from "react";
import { Link } from "react-router-dom";

export default function CategoryCard(props) {
    const url = props.url || ("/categorie-produit/" + props.collectionHandle);

    return (
        <div className="col-sm-6 col-md-6 col-lg-6 mb-4">
            <Link className="linkNoStyle" to={url}>
                <div className="card categoryCard">
                    {props.picture && (
                        <img src={props.picture} className="card-img-top" alt={props.title} />
                    )}
                    <div className="card-body">
                        <h5 className="card-title text-center mb-0 categoryTitle">{props.title}</h5>
                    </div>
                </div>
            </Link>
        </div>
    );
}
import { WebsiteUrl } from "./Constants";

export function imageCropSquare(image, size) {
  const splitUrl = image.src.split('?');
  const notQuery = splitUrl[0];
  const query = splitUrl[1] ? `?${splitUrl[1]}` : '';

  // Use the section before the query
  const imageTokens = notQuery.split('.');

  // Take the token before the file extension and append the dimensions
  const imagePathIndex = imageTokens.length - 2;

  imageTokens[imagePathIndex] = `${imageTokens[imagePathIndex]}_${size}x${size}_crop_center`;

  return `${imageTokens.join('.')}${query}`;
}

export const soonAvailableCategories = {
  //"precommande": "2021-02-06T11:00:00-05:00"
}

export function generateRandomID() {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 10 characters
  // after the decimal.
  return Math.random().toString(36).substr(2, 10);
};

export function generatePostUrl(slug, isAbsolute) {
  const url = "/blog/" + slug;

  if (isAbsolute) {
    return WebsiteUrl + url;
  } else {
    return url;
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import Client from 'shopify-buy/index.unoptimized.umd';
import * as Sentry from '@sentry/react';
import { shopifyClientConfig } from "./Constants";

Sentry.init({
  dsn: "https://7661864c964a493cb12ddbad48283d40@o420864.ingest.sentry.io/5339805",
  environment: process.env.NODE_ENV
});

const client = Client.buildClient(shopifyClientConfig);

ReactDOM.render(
  <React.StrictMode>
    <App client={client} />
  </React.StrictMode>,
  document.getElementById('root')
);
import React, { useState, useEffect } from 'react';

import Header from "./components/Header";
import Footer from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import PageContent from './components/PageContent';
import { Helmet } from 'react-helmet';
import ScrollToTop from './components/ScrollToTop';
import OgPicture from "./images/confections-moufette-og.png";

export default function App(props) {
  let [checkout, setCheckout] = useState(null);
  let [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  let [isCheckoutLoaded, setIsCheckoutLoaded] = useState(false);

  async function createNewCheckout() {
    let createdCheckout = await props.client.checkout.create()
    setCheckout(createdCheckout);
    localStorage.setItem("shopify_checkout_id", createdCheckout.id);
  }

  async function loadCheckout() {
    let checkoutId = localStorage.getItem("shopify_checkout_id");

    try {
      if (checkoutId) {
        try {
          let existingCheckout = await props.client.checkout.fetch(checkoutId);

          if (!existingCheckout.completedAt) {
            setCheckout(existingCheckout);
          } else {
            await createNewCheckout();
          }
        } catch (err) {
          console.error(err);
          await createNewCheckout();
        }
      } else {
        await createNewCheckout();
      }
    } catch (err) {
      console.error(err);
      localStorage.removeItem("shopify_checkout_id");
    }

    setIsCheckoutLoaded(true);
  }

  useEffect(() => {
    loadCheckout();
  }, []);

  function addVariantToCart(variantId, quantity, customAttributes) {
    const lineItemsToAdd = [{
      variantId,
      quantity: parseInt(quantity, 10),
      customAttributes
    }];

    return props.client.checkout.addLineItems(checkout.id, lineItemsToAdd).then(res => {
      setCheckout(res);
    });
  }

  function removeItemFromCart(itemId) {
    setIsCheckoutLoading(true);
    return props.client.checkout.removeLineItems(checkout.id, [itemId]).then(res => {
      setIsCheckoutLoading(false);
      setCheckout(res);
    });
  }

  function updateItemQuantity(itemId, newQuantity) {
    const lineItemsToUpdate = [
      { id: itemId, quantity: newQuantity }
    ];

    setIsCheckoutLoading(true);
    return props.client.checkout.updateLineItems(checkout.id, lineItemsToUpdate).then((res) => {
      setCheckout(res);
      setIsCheckoutLoading(false);

      return res;
    });
  }

  function applyCoupon(coupon) {
    setIsCheckoutLoading(true);
    return props.client.checkout.addDiscount(checkout.id, coupon).then(res => {
      setIsCheckoutLoading(false);
      setCheckout(res);
      
      return res.discountApplications;
    });
  }

  function removeCoupon() {
    setIsCheckoutLoading(true);
    return props.client.checkout.removeDiscount(checkout.id).then(res => {
      setIsCheckoutLoading(false);
      setCheckout(res);
    });
  }

  return (
    <IntlProvider locale={"fr-CA"}>
      <Router>
        <ScrollToTop />
        <Helmet titleTemplate="%s - Confections Moufette">
          <meta property="og:image" content={OgPicture} />
        </Helmet>
        <ErrorBoundary>
          <Header checkout={checkout} />
        </ErrorBoundary>

        <PageContent
          client={props.client}
          checkout={checkout}
          isCheckoutLoaded={isCheckoutLoaded}
          isCheckoutLoading={isCheckoutLoading}
          removeItemFromCart={removeItemFromCart}
          addVariantToCart={addVariantToCart}
          updateItemQuantity={updateItemQuantity}
          applyCoupon={applyCoupon}
          removeCoupon={removeCoupon} />

        <Footer />
      </Router >
    </IntlProvider>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as classNames from "classnames";

export default function DropdownMenu(props) {
    const [isOpened, setIsOpened] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQueryList = window.matchMedia("(max-width: 768px)");

        function customListener(ev) {
            setIsMobile(ev.matches);
        };

        customListener(mediaQueryList);

        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener("change", customListener);

            return () => {
                mediaQueryList.removeEventListener("change", customListener);
            };
        }
    }, []);

    function handleClick() {
        setIsOpened(v => !v);
    }

    useEffect(() => {
        setIsOpened(false);
    }, [isMobile, props.isMobileShow]);

    const button = isMobile ? (
        <span className="dropdown-toggle" onClick={handleClick} id="dropdownMenuButton" aria-haspopup="true" aria-expanded={isOpened}>{props.title}</span>
    ) : (
            <Link className="dropdown-toggle" to={props.url} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{props.title}</Link>
        );

    return (
        <div className="dropdown">
            {button}

            <div className={classNames({ "dropdown-menu": !isMobile, "mobileDropdown": isMobile, "isOpened": isOpened })} aria-labelledby="dropdownMenuButton">
                {props.children}
            </div>
        </div>
    );
}
import React from "react";
import { FormattedNumber } from "react-intl";

export default function CartDiscountRow(props) {
    let valueNote = null;

    if(props.isAutomatic) return null;
    
    if (props.percentage) {
        valueNote = <>(-{props.percentage}%)</>;
    } else if (props.amount) {
        valueNote = <>(-<FormattedNumber value={props.amount} style="currency" currency={"CAD"} />)</>;
    }

    function removeCoupon(ev) {
        ev.preventDefault();

        props.removeCoupon();
    }

    return (
        <div className="discountRow">
            {props.code} {valueNote} {!props.isAutomatic && <a href="#" onClick={removeCoupon} className="linkPrimary"><i className="fa fa-times"></i></a>}
        </div>
    );
}
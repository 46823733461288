import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import LoadingIcon from "./LoadingIcon";

import { getVariantPrice, getVariantCompareAtPrice, getCheapestVariant } from '../PriceHelper';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/scss/image-gallery.scss";
import { Helmet } from "react-helmet";
import PasswordProtected from "./PasswordProtected";
import CategoryRow from "./CategoryRow";
import * as Constants from "../Constants";
import FavoriteLabel from "./FavoriteLabel";

export default function ProductDetail(props) {
    let { productHandle } = useParams();
    let [isLoading, setIsLoading] = useState(false);
    let [product, setProduct] = useState(null);
    let [selectedQuantity, setSelectedQuantity] = useState(1);
    let [selectedOptions, setSelectedOptions] = useState({});
    let [isAddedMessageShown, setIsAddedMessageShown] = useState(false);
    let [isAddingToCart, setIsAddingToCart] = useState(false);
    let [isNotFound, setIsNotFound] = useState(false);
    let [askForPassword, setAskForPassword] = useState(false);
    
    const galleryRef = useRef(null);

    useEffect(() => {
        setIsAddedMessageShown(false);
    }, [productHandle]);

    useEffect(() => {
        setProduct(null);
        setIsLoading(true);
        setIsNotFound(false);
        setAskForPassword(false);

        const productQuery = props.client.graphQLClient.query((root) => {
            root.addField('productByHandle', { args: { handle: productHandle } }, product => {
                product.add("handle");
                product.add("title");
                product.add("description");
                product.add("descriptionHtml");
                product.add('tags');
                product.add('options', option => {
                    option.add("name");
                    option.add("values");
                });
                product.addConnection('images', { args: { first: 40 } }, image => {
                    image.add("src");
                    image.add("altText");
                });
                product.addConnection('variants', { args: { first: 40 } }, variant => {
                    variant.add("price");
                    variant.add("compareAtPrice");
                    variant.add("available");
                    variant.add("image", image => {
                        image.add("originalSrc");
                    })
                    variant.add("selectedOptions", o => {
                        o.add("name");
                        o.add("value");
                    });
                });
            });
        });

        props.client.graphQLClient.send(productQuery).then(({ model, data }) => {
            setIsLoading(false);

            const product = data.productByHandle;

            if(product) {
                if(product.title === "Mini groupe personnalisé - Lilo et Stitch"){
                    setAskForPassword(true);
                }

                let options = {};
                product.options.forEach(option => {
                    options[option.name] = "__DEFAULT__";
                });

                setProduct(product);
                setSelectedOptions(options);
            } else {
                setIsNotFound(true);
            }
        });
    }, [productHandle, props.client]);

    let images = [];
    let selectedVariant = null;
    let hasVariant = false;
    let price = null;
    let compareAtPrice = null;

    if (product) {
        images = product.images.edges.map(img => {
            return {
                original: img.node.src,
                thumbnail: props.client.image.helpers.imageForSize(img.node, { maxWidth: 100, maxHeight: 100 })
            };
        });

        if (product.variants.edges.length > 1) {
            hasVariant = true;
            if (Object.values(selectedOptions).every(v => v !== "__DEFAULT__")) {
                const selectedVariantEdge = product.variants.edges.find(v => v.node.selectedOptions.every(o => o.value === selectedOptions[o.name]));
                selectedVariant = selectedVariantEdge ? selectedVariantEdge.node : null;
            }
        } else {
            selectedVariant = product.variants.edges[0].node;
        }

        if (selectedVariant) {
            price = getVariantPrice(selectedVariant);
            compareAtPrice = getVariantCompareAtPrice(selectedVariant);
        } else {
            let smallerVariant = getCheapestVariant(product);
            
            price = getVariantPrice(smallerVariant.node);
            compareAtPrice = getVariantCompareAtPrice(smallerVariant.node);
        }
    }

    function onOptionChange(optionName, value) {
        setSelectedOptions({ ...selectedOptions, [optionName]: value });
    }

    useEffect(() => {
        if(selectedVariant && images && images.length > 0) {
            const pictureSrc = selectedVariant.image.originalSrc;
            const galleryIndex = images.findIndex(img => img.original === pictureSrc);
            
            if (galleryIndex > -1) {
                galleryRef.current.slideToIndex(galleryIndex);
            }
        }
    }, [selectedVariant]);

    function addToCartClick() {
        setIsAddedMessageShown(false);
        setIsAddingToCart(true);
        props.addVariantToCart(selectedVariant.id, selectedQuantity).then(() => {
            setIsAddedMessageShown(true);
            setIsAddingToCart(false);
        });
    }

    const canAddToCart = selectedVariant && selectedVariant.available;
    const password = askForPassword ? "AudzDesign" : null;

    function getReplacementCollectionHandle(productHandle){
        const loweredHandle = productHandle.toLowerCase();

        if (loweredHandle.includes("bonnet")){
            return "bonnets";
        } else if (loweredHandle.includes("doudouce")){
            return "couvertures";
        } else if (loweredHandle.includes("feuillet") || loweredHandle.includes("wetbag")){
            return "accessoires-pour-couches";
        }

        return "couches";
    }
    
    const isFavorite = product && product.tags && product.tags.map(t => t.toLowerCase()).includes(Constants.FavoriteTagName.toLowerCase());

    const absoluteUrl = product && "https://confectionsmoufette.com/produit/" + product.handle;
    
    function getOptionValue(option, value) {
        console.log(selectedOptions);

        if (product.title.startsWith("Précommande") && option.name === "Délais de confection" && value === "2 à 4 semaines") {
            if(selectedOptions["Dimensions"]?.startsWith("Petite")) {
                return value + " (+5.95$)";
            } else if(selectedOptions["Dimensions"]?.startsWith("Grande")) {
                return value + " (+9.95$)";
            } else {
                return value;
            }
        } else {
            return value;
        }
    }

    return (
        <div className="container pageContent">
            <LoadingIcon isLoading={isLoading} />
            {!isLoading && isNotFound && (
                <>
                    <p className="alert alert-warning">Désolé, ce produit n'est plus disponible.</p>
                    <CategoryRow client={props.client} title="Produits que vous pourriez aimer" collectionHandle={getReplacementCollectionHandle(productHandle)} />
                </>
            )}
            {product && (
                <>
                    <Helmet title={product.title}>
                        <link rel="canonical" href={absoluteUrl} />
                        <meta name="description" content={product.description} />
                        <meta property="og:title" content={product.title} />
                        <meta property="og:description" content={product.description} />
                        <meta property="og:type" content="product" />
                        <meta property="og:url" content={absoluteUrl} />
                        <meta property="og:site_name" content="Confections Moufette" />
                        <meta property="og:image" content={images[0].original} />
                        <meta property="og:image:secure_url" content={images[0].original} />
                        <meta property="og:price:amount" content={price} />
                        <meta property="og:price:currency" content="CAD" />
                    </Helmet>
                    <PasswordProtected password={password}>
                        <div className="row">
                            <div className="col-md-4">
                                <ImageGallery
                                    ref={galleryRef}
                                    renderRightNav={() => null}
                                    renderLeftNav={() => null}
                                    items={images}
                                    showPlayButton={false}
                                />
                                {isFavorite && <FavoriteLabel />}
                            </div>
                            <div className="col-md-8">
                                <h1>{product.title}</h1>
                                <div className="priceLabel">
                                    {price && <FormattedNumber value={price} style="currency" currency={"CAD"} />}
                                </div>
                                
                                {compareAtPrice && (
                                    <div className="compareAtPrice">
                                        <FormattedNumber value={compareAtPrice} style="currency" currency={"CAD"} />
                                    </div>
                                )}

                                <form className="mt-2">
                                    {hasVariant && product.options.map(option => (
                                        <div key={option.id} className="form-group">
                                            <label htmlFor={"options_" + option.id}>{option.name}</label>
                                            <select className="form-control" id={"options_" + option.id} value={selectedOptions[option.name]} onChange={ev => onOptionChange(option.name, ev.target.value)}>
                                                <option key={"__DEFAULT__"} value={"__DEFAULT__"}>Choisir une option</option>
                                                {option.values.map(value => (
                                                    <option key={value} value={value}>{getOptionValue(option, value)}</option>
                                                ))}
                                            </select>
                                        </div>
                                    ))}
                                    {selectedVariant && !selectedVariant.available && (
                                        <div className="alert alert-warning">En rupture d'inventaire</div>
                                    )}
                                    {isAddedMessageShown && (
                                        <div className="alert alert-info">Produit ajouté au <Link className="linkPrimary font-weight-bold" to="/panier">panier</Link></div>
                                    )}
                                    <LoadingIcon isLoading={isAddingToCart} />
                                    <div className="form-inline">
                                        <input disabled={!canAddToCart || isAddingToCart} className="form-control qtyInput mr-2" min="1" type="number" defaultValue={selectedQuantity} onChange={ev => setSelectedQuantity(ev.target.value)}></input>
                                        <button disabled={!canAddToCart || isAddingToCart} className="btn btn-primary mt-1" type="button" onClick={addToCartClick}>Ajouter au panier</button>
                                    </div>
                                </form>
                                <br />
                                <p dangerouslySetInnerHTML={{__html: product.descriptionHtml}}>
                                </p>
                            </div>
                        </div>
                    </PasswordProtected>
                </>
            )}
        </div>
    );
}
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { albumProductHandle } from "../Constants";
import { imageCropSquare } from "../helpers";
import LoadingIcon from "./LoadingIcon";

export default function CartLineItem(props) {
    let [selectedQuantity, setSelectedQuantity] = useState(props.item.quantity);
    let [isDeleting, setIsDeleting] = useState(false);

    function onItemQuantityChange(newQuantity) {
        setSelectedQuantity(newQuantity);

        props.updateItemQuantity(props.item.id, newQuantity).then(checkout => {
            const item = checkout?.lineItems.find(lineItem => lineItem.id === props.item.id);

            if (item) {
                setSelectedQuantity(item.quantity);
            }
        });
    }

    function onRemoveClicked() {
        setIsDeleting(true);

        props.removeItemFromCart(props.item.id).then(() => {
            //setIsDeleting(false);
        });
    }

    useEffect(() => {
        if(props.item.quantity !== selectedQuantity) {
            setSelectedQuantity(props.item.quantity);
        }
    }, [props.item.quantity])

    const isCustomAlbum = props.item.variant.product.handle === albumProductHandle;

    function getDiscountedPrice() {
        try{
            if(props.item.discountAllocations?.length ){
                const discountAllocations = parseFloat(props.item.discountAllocations[0].allocatedAmount?.amount);
                return (props.item.quantity * props.item.variant.price) - discountAllocations;
            }

            return null;
        } catch(err) {
            console.error(err);
            return null;
        }
    }

    const discountedPrice = getDiscountedPrice();

    return (
        <tr className="cartItem">
            <td>
                <Link className="linkPrimary" to={"/produit/" + props.item.variant.product.handle}>
                    <img className="thumbnail mr-1" alt={props.item.variant.image.altText} src={imageCropSquare(props.item.variant.image, 100)} />
                </Link>
                <div className="titleAndOptions">
                    <Link className="linkPrimary" to={"/produit/" + props.item.variant.product.handle}>{props.item.title}</Link>
                    {props.item.variant.selectedOptions.filter(option => option.name !== "Title" || option.value !== "Default Title").map(option => (
                        <div key={option.name}>{!isCustomAlbum && <>{option.name}:&nbsp;</>}{option.value}</div>
                    ))}
                </div>
            </td>
            <td><FormattedNumber value={props.item.variant.price} style="currency" currency={"CAD"} /></td>
            <td>
                <input type="number" min={1} className="form-control qtyInput" value={selectedQuantity} onChange={ev => onItemQuantityChange(parseInt(ev.target.value))} />
            </td>
            <td>
                <span className={classNames({"text-linethrough": !!discountedPrice})}>
                    <FormattedNumber value={props.item.quantity * props.item.variant.price} style="currency" currency={"CAD"} />
                </span>
                {discountedPrice && (
                    <>
                        <br/>
                        <FormattedNumber value={discountedPrice} style="currency" currency={"CAD"} />
                    </>
                )}
            </td>
            <td>
                {!isDeleting && <i onClick={ev => onRemoveClicked()} className="fa fa-times iconPrimary iconClickable"></i>}
                <LoadingIcon isLoading={isDeleting} isFontAwesome={true} />
            </td>
        </tr>
    );
}
import React from "react";
import CoucheDoublureImg from "../images/couche-doublure.jpg";
import BandeDetancheiteImg from "../images/bande-detancheite.jpg";
import QualiteQuebecoise from "../images/qualite-quebecoise.jpg";
import FeaturedProductSection from "./FeaturedProductSection";
import { Helmet } from "react-helmet";
import CategoryCard from "./CategoryCard";

import Banner from "../images/banniere-25.png";
import CoucheImg from "../images/categories/couches.jpg";
import PourMamanImg from "../images/categories/PourMaman.jpg";
import AlbumsImg from "../images/categories/albums.jpg";
import AccessoiresImg from "../images/categories/PourBebe.jpg";
import { albumProductHandle } from "../Constants";

export default function HomePage(props) {
    return (
        <div className="container pageContent">
            <Helmet title="Bienvenue !" />

            {/* <a href="/categorie-produit/taille-unique">
                <img className="img-responsive mb-3" src={Banner} />
            </a> */}

            <div className="row homeRow">
                <div className="col-12">
                    <div className="row">
                        <CategoryCard title="Couches lavables" collectionHandle="couches" picture={CoucheImg} />
                        <CategoryCard title="Pour maman" collectionHandle="pour-maman" picture={PourMamanImg} />
                        <CategoryCard title="Pour bébé et enfant" collectionHandle="pour-bebe-et-enfant" picture={AccessoiresImg} />
                        <CategoryCard title="Album photo" url={"/produit/" + albumProductHandle} picture={AlbumsImg} />
                    </div>
                </div>
            </div>

            <FeaturedProductSection client={props.client} addVariantToCart={props.addVariantToCart} />
            <div className="row homeRow">
                <div className="col-6">
                    <img className="img-fluid" src={CoucheDoublureImg} alt="Couches entièrement doublées" />
                </div>
                <div className="col-6">
                    <div className="h-100 d-flex align-content-center flex-wrap">
                        <h2>Couches entièrement doublées</h2>
                        <p>Les deux couches de PUL (polyurétane laminé) offrent une protection additionnelle contre les risques de délamination.</p>
                        <p>De plus, elles réduisent considérablement les risques de fuites tout en étant plus résistantes.</p>
                    </div>
                </div>
            </div>
            <div className="row homeRow">
                <div className="col-6">
                    <div className="h-100 d-flex align-content-center flex-wrap">
                        <h2>Bande d’étanchéité à l’avant</h2>
                        <p>Procure une sécurité optimale contre les fuites, surtout pour nos petits rampeurs et ceux qui aiment se reposer sur le ventre.</p>
                    </div>
                </div>

                <div className="col-6">
                    <img className="img-fluid" src={BandeDetancheiteImg} alt="Couches entièrement doublées" />
                </div>
            </div>

            <div className="row homeRow">
                <div className="col-6">
                    <img className="img-fluid" src={QualiteQuebecoise} alt="Couches entièrement doublées" />
                </div>
                <div className="col-6">
                    <div className="h-100 d-flex align-content-center flex-wrap">
                        <h2>De qualité québécoise</h2>
                        <p>Tous nos produits sont confectionnés par des artisans québécois. Nous encourageons l’économie locale en nous approvisionnant chez les entreprises d’ici.</p>
                        <p>Les 14 ajustements à la taille et les quatre ajustements en hauteur permettent aux bébés de 8 à 35 livres* de se sentir à l’aise dans les couches lavables Moufette.</p>
                        <p>
                            <em>*Information à titre indicatif seulement, tout dépend de la morphologie de l’enfant.</em>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from "react";

export default function UploaderPicturePreview(props) {
    return (
        <div className="card">
            <img key={props.file.ref} alt="" className="card-img-top" src={props.file.publicUrl} />
            <button type="button" className="btn btn-sm btn-danger" onClick={ev => props.onDelete(props.file)}>
                <i className="fa fa-times"></i>
            </button>
        </div>
    )
}
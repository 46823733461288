import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function SearchBar(props) {
  const [searchInput, setSearchInput] = useState("");

  let history = useHistory();

  function handleSearch(ev) {
    ev.preventDefault();

    if (searchInput) {
      history.push("/recherche?t=" + encodeURIComponent(searchInput));
    }
  }

  return (
    <form onSubmit={handleSearch} className={props.className}>
      <div className="input-group mb-3">
        <input
          className="form-control"
          type="search"
          placeholder="Recherche"
          value={searchInput}
          onChange={(ev) => setSearchInput(ev.target.value)}
        />
        <div className="input-group-append">
          <button
            onClick={handleSearch}
            className="btn btn-outline-secondary"
            type="submit"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  );
}

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { wordpressPublicApiBaseUrl } from "../Constants";
import BlogPostCard from "./BlogPostCard";
import LoadingIcon from "./LoadingIcon";

export default function BlogPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchPosts() {
            setIsLoading(true);
            const result = await axios.get(wordpressPublicApiBaseUrl + "/posts");

            setIsLoading(false);
            setPosts(result.data.posts);
        }

        fetchPosts();
    }, []);

    return (
        <div className="container pageContent">
            <Helmet title="Blog" />
            <h1>Blog</h1>

            <div className="row">
                {posts.map(post => (
                    <div key={post.ID} className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-4">
                        <BlogPostCard post={post} />
                    </div>
                ))}
            </div>
            <LoadingIcon isLoading={isLoading} />
        </div>
    );
}
import React from 'react';
import { subCategories } from '../Constants';
import SmallCategoryCard from "./SmallCategoryCard";

export default function SubCategoriesRow(props) {
    if (!Object.keys(subCategories).includes(props.collectionHandle)) {
        return null;
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    {subCategories[props.collectionHandle].map(c => (
                        <SmallCategoryCard key={c.url} title={c.title} url={c.url} picture={c.picture} />
                    ))}
                </div>
            </div>
        </div>
    );
}
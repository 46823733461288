import React from "react";
import { FormattedDate } from "react-intl";
import { generatePostUrl } from "../helpers";

export default function BlogPostCard(props) {

    function sanitizeExcerpt(excerpt) {
        const toRemove = ["<p>", "</p>", "\n", "[&hellip;]"];

        let sanitized = excerpt;

        toRemove.forEach(x => sanitized = sanitized.replaceAll(x, ""));

        return sanitized + "...";
    }

    const postUrl = generatePostUrl(props.post.slug);

    return (
        <div className="card">
            {props.post.featured_image && <a href={postUrl}><img src={props.post.featured_image} className="card-img-top" alt={props.post.title} /></a>}
            <div className="card-body">
                <h6 className="card-subtitle mb-2 text-muted"><FormattedDate value={props.post.date} year="numeric" month="long" day="numeric" /></h6>
                <a href={postUrl} className="linkPrimary">
                    <h5 className="card-title">{props.post.title}</h5>
                </a>
                <p className="card-text">{sanitizeExcerpt(props.post.excerpt)}</p>
                <a href={postUrl} className="btn btn-primary">Lire</a>
            </div>
        </div>
    );
}
import React from "react";

export default function ProductOptionDropdown(props) {

    let labelFn = props.labelFn || (v => v);
    
    return (
        <div className="form-group">
            <label htmlFor={"options_" + props.id}>{props.name}</label>
            <select className="form-control" id={"options_" + props.id} value={props.selectedValue} onChange={ev => props.onOptionChange(ev.target.value)}>
                <option value={"__DEFAULT__"}>Choisir une option</option>
                {props.values.map(value => (
                    <option key={value} value={value}>{labelFn(value)}</option>
                ))}
            </select>
        </div>
    );
}
import React from "react";
import * as classNames from "classnames";

export default function LoadingIcon(props) {

    if (props.isLoading) {
        if (props.isFontAwesome) {
            return (
                <i className="fa fa-spinner fa-pulse fa-2x fa-fw textPrimary"></i>
            );
        } else {
            return (
                <div className={classNames("lds-default", props.className)}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            );
        }
    } else {
        return null;
    }
}
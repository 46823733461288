import React from "react";
import { Helmet } from "react-helmet";

export default function WarrantyPage() {
  return (
    <div className="container pageContent">
      <Helmet title="Garantie" />

      <h1>Politique de remboursement </h1>

      <h3>Retour</h3>
      <p>
        Afin de valider si vous êtes éligible à un retour de marchandise,
        veuillez nous contacter par courriel à{" "}
        <a className="linkPrimary" href="mailto:info@confectionsmoufette.com">
          info@confectionsmoufette.com
        </a>{" "}
        avec une description précise de votre problème, des photos et une copie
        de votre reçu d’achat original. Aucun retour, échange ni remboursement
        ne sera autorisé sans votre preuve d’achat originale, ni sur la
        marchandise déjà utilisée. Les frais de livraison de retour sont à vos
        frais. Vous disposez d’un maximum de dix jours suite à la réception de
        votre marchandise pour entamer ces démarches.
      </p>

      <h3>Garantie</h3>

      <p>
        Malgré l’inspection scrupuleuse faite avant l’envoie des produits, un
        défaut peut survenir. Pour tout défaut de fabrication, vous disposez de
        trois jours ouvrables suite à la réception pour nous en faire part.
        Veuillez nous contacter par courriel à info@confectionsmoufette.com avec
        une description précise de votre problème, des photos et une copie de
        votre reçu d’achat original.{" "}
      </p>

      <p>
        Confections Moufette offre une garantie de six mois sur les snaps et les
        élastiques et de trois mois sur les coutures et la délamination du PUL.
        Les mailles dans l’AWJ, les taches, la décoloration du PUL, les odeurs,
        les couches de seconde main et le fit sont exclus.
      </p>
    </div>
  );
}

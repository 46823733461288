const globalDiscountRate = 0;

export const hasGlobalDiscountRate = globalDiscountRate !== 0;

export function getVariantPrice(variant) {
    if (globalDiscountRate) {
        return parseFloat(variant.price) * (1 - globalDiscountRate);
    } else {
        return parseFloat(variant.price);
    }
}

export function getVariantCompareAtPrice(variant) {
    if (globalDiscountRate) {
        return variant.price;
    } else if (variant.compareAtPrice) {
        return variant.compareAtPrice;
    }

    return null;
}

export function getCheapestVariant(product) {
    return product.variants.edges.map(v => {
        return {
            price: getVariantPrice(v.node),
            compareAtPrice: getVariantCompareAtPrice(v.node),
            variant: v
        }
    }).reduce((r,c) => c.price < r.price ? c : r).variant;
}
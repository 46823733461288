import React, { useEffect, useState } from "react";
import GridViewProduct from "./GridViewProduct";

export default function CategoryRow(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [products, setProducts] = useState([]);
    let [collection, setCollection] = useState({});

    useEffect(() => {
        setCollection({});
        setProducts([]);
        setIsLoading(true);
        const collectionQuery = props.client.graphQLClient.query((root) => {
            root.addField('collectionByHandle', { args: { handle: props.collectionHandle } }, collection => {
                collection.add("title");
                collection.add("image", image => {
                    image.add("originalSrc");
                })
                collection.addConnection('products', { args: { first: 4 } }, (product) => {
                    product.add('handle');
                    product.add('title');
                    product.addConnection('images', { args: { first: 10 } }, image => {
                        image.add("src");
                        image.add("altText");
                    });
                    product.addConnection('variants', { args: { first: 10 } }, variant => {
                        variant.add("price");
                        variant.add("available");
                    });
                });
            });
        });

        props.client.graphQLClient.send(collectionQuery).then(({ model, data }) => {
            setIsLoading(false);
            setCollection(data.collectionByHandle);
            setProducts(data.collectionByHandle.products.edges.map(e => e.node));
        }).catch(err => {
            console.error(err);
        });
    }, [props.client]);

    const productsWithImages = products.filter(product => product.images.edges.length > 0);

    return (
        (isLoading || productsWithImages.length > 0) && (
            <div className="row homeRow">
                <div className="col-12">
                    <h1>{props.title}</h1>
                    <div className="row">
                        {productsWithImages.map(product => (
                            <GridViewProduct key={product.id} product={product} client={props.client} addVariantToCart={props.addVariantToCart} />
                        ))}
                    </div>
                </div>
            </div>
        )
    );
}
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingIcon from "./LoadingIcon";
import GridViewProduct from "./GridViewProduct";
import { Helmet } from "react-helmet";
import * as helpers from '../helpers';
import { FormattedDate, FormattedTime } from "react-intl";
import SubCategoriesRow from "./SubCategoriesRow";
import PreorderBanner from "../images/precommande-doudoux.png";

export default function CategoryPage(props) {

    let { categoryHandle } = useParams();
    let [isLoading, setIsLoading] = useState(false);
    let [products, setProducts] = useState([]);
    let [collection, setCollection] = useState({});
    let [isNotFound, setIsNotFound] = useState(false);

    let soonDate = helpers.soonAvailableCategories[categoryHandle];
    let isSoon = soonDate && new Date(soonDate) > new Date();

    useEffect(() => {
        setIsNotFound(false);
        setCollection({});
        setProducts([]);
        setIsLoading(true);
        const collectionQuery = props.client.graphQLClient.query((root) => {
            root.addField('collectionByHandle', { args: { handle: categoryHandle } }, collection => {
                collection.add("title");
                collection.add("image", image => {
                    image.add("originalSrc");
                })
                collection.addConnection('products', { args: { first: 100 } }, (product) => {
                    product.add('handle');
                    product.add('title');
                    product.add('tags');
                    product.addConnection('images', { args: { first: 10 } }, image => {
                        image.add("src");
                        image.add("altText");
                    });
                    product.addConnection('variants', { args: { first: 10 } }, variant => {
                        variant.add("price");
                        variant.add("compareAtPrice");
                        variant.add("available");
                    });
                });
            });
        });

        props.client.graphQLClient.send(collectionQuery).then(({ model, data }) => {
            setIsLoading(false);

            if (data.collectionByHandle) {
                setCollection(data.collectionByHandle);
                setProducts(data.collectionByHandle.products.edges.map(e => e.node));
            } else {
                setIsNotFound(true);
            }
        }).catch(err => {
            console.error(err);
        });
    }, [categoryHandle, props.client]);

    const productsWithImages = products.filter(product => product.images.edges.length > 0);

    let ogImage = null;
    if (collection.image) {
        ogImage = collection.image.originalSrc;
    } else if (productsWithImages.length > 0) {
        ogImage = productsWithImages[0].images.edges[0].node.src;
    }

    return (
        <div className="container pageContent">
            <Helmet title={collection.title}>
                {ogImage && <meta property="og:image" content={ogImage} />}
            </Helmet>

            <SubCategoriesRow collectionHandle={categoryHandle} />

            {isSoon ? (
                <div className="text-center">
                    <i className="fa fa-3x fa-clock-o"></i>
                    <br />
                    <h2>En ligne le <FormattedDate value={soonDate} day="numeric" month="long" year="numeric" /> à <FormattedTime value={soonDate} /></h2>
                </div>
            ) : (
                <>
                    <LoadingIcon isLoading={isLoading} />

                    {!isLoading && isNotFound && (
                        <>
                            <p className="alert alert-warning">Désolé, cette catégorie n'est plus disponible.</p>
                        </>
                    )}

                    <div className="row">
                        <div className="col-12">
                            <h1>{collection.title}</h1>
                        </div>
                    </div>

                    {categoryHandle === "precommande" && (
                        <img className="img-responsive mb-3" src={PreorderBanner} />
                    )}

                    <div className="row">
                        {productsWithImages.map(product => (
                            <GridViewProduct key={product.id} product={product} client={props.client} addVariantToCart={props.addVariantToCart} />
                        ))}

                        {products.length === 0 && !isLoading && !isNotFound && (
                            <div className="col-12">
                                <p className="alert alert-info">Il n'y a aucun produit dans cette catégorie.</p>
                            </div>
                        )}
                    </div>
                </>
            )
            }

        </div >
    );
}
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingIcon from "./LoadingIcon";
import GridViewProduct from "./GridViewProduct";
import { Helmet } from "react-helmet";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function SearchResultPage(props) {
  let query = useQuery();

  let [isLoading, setIsLoading] = useState(false);
  let [products, setProducts] = useState([]);

  const searchTerm = query.get("t");

  useEffect(() => {
    setProducts([]);
    setIsLoading(true);
    const productsQuery = props.client.graphQLClient.query((root) => {
      root.addConnection(
        "products",
        { args: { query: searchTerm, first: 100 } },
        (product) => {
          product.add("handle");
          product.add("title");
          product.add("tags");
          product.addConnection("images", { args: { first: 10 } }, (image) => {
            image.add("src");
            image.add("altText");
          });
          product.addConnection(
            "variants",
            { args: { first: 10 } },
            (variant) => {
              variant.add("price");
              variant.add("compareAtPrice");
              variant.add("available");
            }
          );
        }
      );
    });

    props.client.graphQLClient
      .send(productsQuery)
      .then(({ model, data }) => {
        setIsLoading(false);

        if (data.products) {
          setProducts(data.products.edges.map((e) => e.node));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [searchTerm, props.client]);

  const productsWithImages = products.filter(
    (product) => product.images.edges.length > 0
  );

  return (
    <div className="container pageContent">
      <Helmet title={searchTerm}></Helmet>

      <div className="row">
        <div className="col-12">
          <h1>
            Résultats de recherche pour <strong>"{searchTerm}"</strong>
          </h1>
        </div>
      </div>

      <LoadingIcon isLoading={isLoading} />

      <div className="row">
        {productsWithImages.map((product) => (
          <GridViewProduct
            key={product.id}
            product={product}
            client={props.client}
            addVariantToCart={props.addVariantToCart}
          />
        ))}

        {products.length === 0 && !isLoading && (
          <div className="col-12">
            <p className="alert alert-info">
              Il n'y a aucun produit pour cette recherche.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";

import HeartIcon from "../images/heart.png";

export default function FavoriteLabel() {
    return (
        <div className="favoriteMark">
            <img src={HeartIcon} className="heartIcon" alt="Coup de coeur" />
            <span>Coup de coeur</span>
        </div>
    );
}
import React from "react";
import { facebookPageUrl } from "../Constants";
import { Helmet } from "react-helmet";
import ParkingPicture from "../images/stationnement.png";

export default function ContactUsPage() {

    return (
        <div className="container pageContent">
            <Helmet title="Nous joindre" />
            <div className="row">
            <div className="col-lg-6 col-xs-12">
                    <h2>Nos coordonnées</h2>
                    <i className="fa fa-lg fa-phone" aria-hidden="true"></i><a className="pl-2 linkPrimary" href="tel:+15147785732">514-778-5732</a>
                    <br />
                    <i className="fa fa-lg fa-facebook-square" aria-hidden="true"></i><a className="linkPrimary pl-2" href={facebookPageUrl} target="_blank" rel="noopener noreferrer">{facebookPageUrl}</a>
                    <br />
                    <i className="fa fa-lg fa-envelope" aria-hidden="true"></i><a className="linkPrimary pl-2" href="mailto:info@confectionsmoufette.com">info@confectionsmoufette.com</a>
                    <br/>
                    <div className="mt-2">Veuillez nous contacter pour planifier une cueillette à domicile.</div>
                </div>
            </div>
        </div>
    );
}
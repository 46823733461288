import React, { useState } from "react";
import * as classNames from "classnames";

export default function PasswordProtected(props) {
    let [hasEntered, setHasEntered] = useState(false);
    let [isInvalidPassword, setIsInvalidPassword] = useState(false);
    let [editedPassword, setEditedPassword] = useState("");

    function validatePassword(ev) {
        ev.preventDefault();

        setIsInvalidPassword(false);
        if (editedPassword === props.password) {
            setHasEntered(true);
        } else {
            setIsInvalidPassword(true);
        }
    }

    if (props.password && !hasEntered) {
        return (
            <form onSubmit={validatePassword}>
                <h2>Protection par mot de passe</h2>
                <div className="input-group">
                    <input style={{maxWidth: "200px"}} className={classNames("form-control", { "is-invalid": isInvalidPassword })} type="text" value={editedPassword} onChange={ev => setEditedPassword(ev.target.value)} autoComplete="false" />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-primary">Valider</button>
                    </div>
                </div>
            </form>
        );
    } else {
        return props.children;
    }
}
import React, { useState, useEffect, useRef } from "react";
import LoadingIcon from "./LoadingIcon";
import {getVariantCompareAtPrice, getVariantPrice} from "../PriceHelper";
import Helmet from "react-helmet";
import ImageGallery from 'react-image-gallery';
import {FormattedNumber} from "react-intl";
import {Link} from "react-router-dom";
import {albumProductHandle} from "../Constants";
import ProductOptionDropdown from "./ProductOptionDropdown";
import {generateRandomID} from "../helpers";
import { useHistory } from "react-router-dom";

import FileUploader from "react-firebase-file-uploader";
import firebase from "@firebase/app";
import '@firebase/storage';
import UploaderPicturePreview from "./UploaderPicturePreview";
import classNames from "classnames";

const config = {
    apiKey: "AIzaSyCa_7g5F4nb0zsRLinz2iS0BGrz_5qoscA",
    authDomain: "confectionsmoufette.firebaseapp.com",
    projectId: "confectionsmoufette",
    storageBucket: "confectionsmoufette.appspot.com",
    messagingSenderId: "702843807522",
    appId: "1:702843807522:web:e5eae389d567433a08677b"
  };

firebase.initializeApp(config);

const testPictures = [
    // {ref:"1", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2Fdcf4f9c6-8088-407e-96b0-128e03ebdaf0.png?alt=media"},
    // {ref:"3", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2Fdcf4f9c6-8088-407e-96b0-128e03ebdaf0.png?alt=media"},
    // {ref:"2", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2F8d4d2b88-d57d-4940-85d7-d14b4cc192c5.png?alt=media"},
    // {ref:"4", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2F8d4d2b88-d57d-4940-85d7-d14b4cc192c5.png?alt=media"},
    // {ref:"6", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2F8d4d2b88-d57d-4940-85d7-d14b4cc192c5.png?alt=media"},
    // {ref:"5", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2Fdcf4f9c6-8088-407e-96b0-128e03ebdaf0.png?alt=media"},
    // {ref:"7", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2Fdcf4f9c6-8088-407e-96b0-128e03ebdaf0.png?alt=media"},
    // {ref:"8", publicUrl:"https://firebasestorage.googleapis.com/v0/b/confectionsmoufette.appspot.com/o/albums%2Ffsz8gccen%2F8d4d2b88-d57d-4940-85d7-d14b4cc192c5.png?alt=media"}
];

const phrases = [
    "Mon bonheur à moi... C'est nos moments à nous.",
    "La famille c'est là où la vie commence et où l'amour ne finit jamais.",
    "J'adore ma vie depuis le moment où tu y es entré.",
    "La famille c'est comme les branches d'un arbre. Nous grandissons tous dans différentes directions, mais nous avons les mêmes racines...",
];
const customPhraseLabel = "** Phrase personnalisée (+5$) **";
const minPhotoCount = 12;
const maxPhotoCount = 14;
const allowedExts = [".png", ".jpg", ".jpeg"];

function generateProjectId(){
    if (process.env.NODE_ENV !== "production") {
        return "1 - " + process.env.NODE_ENV.toUpperCase() + " - " + generateRandomID();
    } else {
        return generateRandomID();
    }
}

export default function AlbumPage(props) {
    let [isLoading, setIsLoading] = useState(false);
    let [product, setProduct] = useState(null);
    let [selectedOptions, setSelectedOptions] = useState({});
    let [isAddedMessageShown, setIsAddedMessageShown] = useState(false);
    let [isAddingToCart, setIsAddingToCart] = useState(false);
    let [projectId, setProjectId] = useState(generateProjectId());
    let [isUploading, setIsUploading] = useState(false);
    let [inProgressUploadCount, setInProgressUploadCount] = useState(0);
    let [uploadedFiles, setUploadedFiles] = useState(testPictures);
    
    let [phrase, setPhrase] = useState(null);
    let [childName, setChildName] = useState("");
    
    let history = useHistory();
    const galleryRef = useRef(null);

    useEffect(() => {
        setProduct(null);
        setIsLoading(true);

        props.client.product.fetchByHandle(albumProductHandle).then((product) => {
            setIsLoading(false);

            if(product) {
                let options = {};
                product.options.forEach(option => {
                    options[option.name] = "__DEFAULT__";
                });

                setProduct(product);
                setSelectedOptions(options);
            } else {
                console.error("Album product not found");
            }
        });
    }, [props.client]);

    let images = [];
    let selectedVariant = null;
    let price = null;
    let compareAtPrice = null;

    if (product) {
        images = product.images.map(img => {
            return {
                original: img.src,
                thumbnail: props.client.image.helpers.imageForSize(img, { maxWidth: 100, maxHeight: 100 })
            };
        });

        if (Object.values(selectedOptions).every(v => v !== "__DEFAULT__")) {
            selectedVariant = product.variants.find(v => v.selectedOptions.every(o => o.value === selectedOptions[o.name]));
        }

        if (selectedVariant) {
            price = getVariantPrice(selectedVariant);
            compareAtPrice = getVariantCompareAtPrice(selectedVariant);
        } else {
            let smallerVariant = product.variants.map(v => {
                return {
                    price: getVariantPrice(v),
                    compareAtPrice: getVariantCompareAtPrice(v)
                }
            }).reduce((r,c) => c.price < r.price ? c : r);
            price = smallerVariant.price;
            compareAtPrice = smallerVariant.compareAtPrice;
        }
    }

    function onOptionChange(optionName, value) {
        setSelectedOptions({ ...selectedOptions, [optionName]: value });
    }

    function addToCartClick(ev) {
        ev.preventDefault();

        setIsAddedMessageShown(false);
        setIsAddingToCart(true);
        props.addVariantToCart(selectedVariant.id, 1,[
                {
                    key: "Nom de l'enfant",
                    value: childName
                },
                {
                    key: "Phrase",
                    value: phrase
                },
                {
                    key: "Project ID",
                    value: projectId
                }
            ]
        ).then(() => {
            setIsAddedMessageShown(true);
            setIsAddingToCart(false);

            history.push("/panier");
        });
    }

    const canAddToCart = selectedVariant && selectedVariant.available && childName && phrase && uploadedFiles.length >= minPhotoCount && uploadedFiles.length <= maxPhotoCount;

    const dentalSectionOption = product && product.options.find(x => x.name === "Coin de dentition");

    const modelOption = product && product.options.find(x => x.name === "Modèle");

    const phraseOption = product && product.options.find(x => x.name === "Avec phrase personnalisée");
    const phraseCustomOptionValue = product && phraseOption.values.find(x => x.value.toLowerCase().includes("avec"));
    const phraseStandardOptionValue = product && phraseOption.values.find(x => x.value.toLowerCase().includes("sans"));
    const customPhraseSelected = product && selectedOptions[phraseOption.name] === phraseCustomOptionValue.value;
    
    function onFileUploadSuccess(fileName){
        const ref = "albums/" + projectId + "/" + fileName;
        const publicUrl = "https://firebasestorage.googleapis.com/v0/b/" + config.storageBucket + "/o/" + ref.replaceAll("/", "%2F") + "?alt=media";
        console.log("Uploaded: "+ publicUrl);
        setUploadedFiles(oldFiles => [...oldFiles, {ref,publicUrl}]);

        setInProgressUploadCount(c => {
            if (c === 1) {
                setIsUploading(false);
            }

            return  c - 1;
        });
    }

    function onFileUploadStart (file, task) {
        const isValidExt = allowedExts.some(ext => file.name.toLowerCase().endsWith(ext));

        if (!isValidExt) {
            console.error(file.name + " has an invalid extension, cancelling upload.");
            task.cancel();
            return;
        } 
        
        setIsUploading(true);
        setInProgressUploadCount(c => c + 1);
    }
    
    function onFileUploadError(error) {
        setInProgressUploadCount(c => {
            if (c === 1) {
                setIsUploading(false);
            }
            
            return  c - 1;
        });

        console.error(error);
    }
    
    async function onFileDelete(file) {
        console.log("removing file: " + file.ref);

        const fileRef = firebase.storage().ref(file.ref);

        try {
            await fileRef.delete();

            setUploadedFiles(oldFiles => oldFiles.filter(f => f.ref !== file.ref));
        } catch(error) {
            console.error(error);
        }
    }

    function onSelectedModelChange(value) {
        const matchingVariant = product.variants.find(v => v.selectedOptions.some(o => o.name === modelOption.name && o.value === value));
        if(matchingVariant){
            const pictureSrc = matchingVariant.image.src;
            const galleryIndex = images.findIndex(img => img.original === pictureSrc);
            
            if (galleryIndex > -1) {
                galleryRef.current.slideToIndex(galleryIndex);
            }
        }

        onOptionChange(modelOption.name, value);
    }

    function onSelectedPhraseChange(value) {
        if (value === "__DEFAULT__") {
            setPhrase(null);
            setSelectedOptions(phraseOption.name, "__DEFAULT__");
        } else {
            const isCustom = !phrases.includes(value);
            onOptionChange(phraseOption.name, isCustom ? phraseCustomOptionValue.value : phraseStandardOptionValue.value);
    
            if (isCustom) {
                setPhrase("");
            } else {
                setPhrase(value);
            }
        }
    }
    
    return (
        <div className="container pageContent">
            <LoadingIcon isLoading={isLoading} />
            {product && (
                <>
                    <Helmet title={product.title}>
                        <meta name="description" content={product.description} />
                        <meta property="og:title" content={product.title} />
                        <meta property="og:description" content={product.description} />
                        <meta property="og:type" content="product" />
                        <meta property="og:url" content={"https://confectionsmoufette.com/produit/" + product.handle} />
                        <meta property="og:site_name" content="Confections Moufette" />
                        <meta property="og:image" content={images[0].original} />
                        <meta property="og:image:secure_url" content={images[0].original} />
                        <meta property="og:price:amount" content={price} />
                        <meta property="og:price:currency" content="CAD" />
                    </Helmet>
                        <div className="row">
                            <div className="col-md-4">
                                <ImageGallery
                                    ref={galleryRef}
                                    renderRightNav={() => null}
                                    renderLeftNav={() => null}
                                    items={images}
                                    showPlayButton={false}
                                />
                            </div>
                            <div className="col-md-8">
                                <h1>{product.title}</h1>
                                <p dangerouslySetInnerHTML={{__html: product.descriptionHtml}}>
                                </p>
                                <div className="priceLabel">
                                    {price && <FormattedNumber value={price} style="currency" currency={"CAD"} />}
                                </div>
                                
                                {compareAtPrice && (
                                    <div className="compareAtPrice">
                                        <FormattedNumber value={compareAtPrice} style="currency" currency={"CAD"} />
                                    </div>
                                )}

                                <form className="mt-2" onSubmit={ev => addToCartClick(ev)}>
                                    
                                    <div className="form-group">
                                        <label htmlFor="child_name">Nom de l'enfant</label>
                                        <input 
                                            required={true} 
                                            type="text" 
                                            className="form-control" 
                                            id="child_name" 
                                            value={childName} 
                                            onChange={ev => setChildName(ev.target.value)} />
                                    </div>
                                    
                                    <ProductOptionDropdown 
                                            id={dentalSectionOption.id}
                                            name={dentalSectionOption.name} 
                                            values={dentalSectionOption.values.map(v => v.value)} 
                                            selectedValue={selectedOptions[dentalSectionOption.name]} 
                                            onOptionChange={value => onOptionChange(dentalSectionOption.name, value)}
                                            labelFn={v => v.toLowerCase().startsWith("avec") ? "Oui (+3$)" : v.toLowerCase().startsWith("sans") ? "Non" : v} />
                                            
                                    <ProductOptionDropdown 
                                        id={modelOption.id}
                                        name={modelOption.name} 
                                        values={modelOption.values.map(v => v.value)} 
                                        selectedValue={selectedOptions[modelOption.name]} 
                                        onOptionChange={value => onSelectedModelChange(value)} />
                                        
                                    <ProductOptionDropdown 
                                        id="phraseOption"
                                        name="Phrase" 
                                        values={[...phrases, customPhraseLabel]}
                                        selectedValue={phrase === null ? "__DEFAULT__" : phrases.includes(phrase) ? phrase : customPhraseLabel}
                                        onOptionChange={value => onSelectedPhraseChange(value)} />

                                    {customPhraseSelected && (
                                        <div className="form-group">
                                            <label htmlFor="custom_phrase">Entrez votre phrase personnalisée</label>
                                            <input 
                                                required={true} 
                                                maxLength={100}
                                                type="text" 
                                                className="form-control" 
                                                id="custom_phrase" 
                                                value={phrase} 
                                                onChange={ev => setPhrase(ev.target.value)} />
                                        </div>
                                    )}

                                    <label className={classNames("fileUploadCustomBtn btn btn-secondary", { "disabled": isUploading })}>Téléverser vos fichiers
                                        <FileUploader
                                            disabled={isUploading}
                                            accept={allowedExts.join()}
                                            randomizeFilename={true}
                                            multiple={true}
                                            hidden={true}
                                            storageRef={firebase.storage().ref("albums/" + projectId)}
                                            onUploadStart={onFileUploadStart}
                                            onUploadError={onFileUploadError}
                                            onUploadSuccess={onFileUploadSuccess}
                                            onProgress={(ev) => console.log(ev)}
                                        />
                                    </label>
                                    <LoadingIcon isLoading={isUploading} className="loading-small mr-2"/>
                                    <span className="ml-2">{uploadedFiles.length} photos sélectionnées</span>

                                    {uploadedFiles.length > 0 && (
                                        <div className="albumUploadedFiles gallery card-columns">
                                            {uploadedFiles.map(file => (
                                                <UploaderPicturePreview key={file.ref} file={file} onDelete={onFileDelete} />
                                            ))}
                                        </div>
                                    )}

                                    {(uploadedFiles.length < minPhotoCount || uploadedFiles.length > maxPhotoCount) && (
                                        <div className="alert alert-info mt-2" role="alert">
                                            Le nombre de photos doit être entre {minPhotoCount} et {maxPhotoCount}
                                        </div>
                                    )}

                                    {selectedVariant && !selectedVariant.available && (
                                        <div className="alert alert-warning">En rupture d'inventaire</div>
                                    )}
                                    {isAddedMessageShown && (
                                        <div className="alert alert-info">Produit ajouté au <Link className="linkPrimary font-weight-bold" to="/panier">panier</Link></div>
                                    )}

                                    <LoadingIcon isLoading={isAddingToCart} />
                                    <div className="form-inline mt-2">
                                        <button disabled={!canAddToCart || isAddingToCart || isUploading} className="btn btn-primary mt-1" type="submit">Ajouter au panier</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                </>
            )}
        </div>
    );
}
import React from "react";
import { Link } from "react-router-dom";

export default function CategoryCard(props) {
    const url = props.url || ("/categorie-produit/" + props.collectionHandle);

    return (
        <div className="col-sm-6 col-md-4 col-lg-2 mb-2">
            <Link className="linkNoStyle" to={url}>
                <div className="card smallCategoryCard">
                    <div className="card-body">
                        <h5 className="card-title text-center mb-0 categoryTitle">{props.title}</h5>
                    </div>
                </div>
            </Link>
        </div>
    );
}
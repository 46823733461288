import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoPicture from "../images/logo-moufette.png";
import * as classNames from "classnames";
import DropdownMenu from "./DropdownMenu";
import ErrorBoundary from "./ErrorBoundary";
import SearchBar from "./SearchBar";
import {
  subCategories,
  albumProductHandle,
  facebookPageUrl,
} from "../Constants";

export default function Header(props) {
  let [mobileShown, setMobileShown] = useState(false);

  let location = useLocation();

  let bagQuantity = 0;

  if (props.checkout) {
    bagQuantity = props.checkout.lineItems
      .map((item) => item.quantity)
      .reduce((a, b) => a + b, 0);
  }

  useEffect(() => {
    setMobileShown(false);
  }, [location]);

  return (
    <div className="headerContainer">
      <div className="topBanner">
        Livraison gratuite pour les commandes de 120$ et plus!
      </div>
      <div className="container">
        <div className="row header">
          <div className="col-4">
            <Link to="/">
              <img
                className="logoImg"
                src={LogoPicture}
                alt="Logo Confections Moufette"
              />
            </Link>
          </div>
          <div className="col-8">
            <div className="socialIcons">
              <a
                href={facebookPageUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-facebook-square fa-2x"></i>
              </a>
            </div>

            <button
              className="btn btn-default burgerToggle"
              onClick={() => setMobileShown(!mobileShown)}
            >
              {!mobileShown ? (
                <i className="fa fa-bars mr-2" aria-hidden="true"></i>
              ) : (
                <i className="fa fa-times mr-2" aria-hidden="true"></i>
              )}
              Menu
            </button>

            <Link className="mobileCartBtn" to="/panier">
              <i className="fa fa-shopping-cart fa-2x"></i>
              {bagQuantity > 0 && (
                <span className="badge badge-pill badge-primary">
                  {bagQuantity}
                </span>
              )}
            </Link>

            <SearchBar className="desktopSearchBar" />
          </div>
        </div>

        <SearchBar className="mobileSearchBar" />

        <ErrorBoundary>
          <div className="row">
            <ul
              className={classNames("menuList", { mobileShown: mobileShown })}
            >
              <li>
                <DropdownMenu
                  title="Couches lavables"
                  url={"/categorie-produit/couches"}
                  isMobileShow={mobileShown}
                >
                  {subCategories.couches.map((c) => (
                    <Link key={c.url} className="dropdown-item" to={c.url}>
                      {c.title}
                    </Link>
                  ))}
                </DropdownMenu>
              </li>
              <li>
                <DropdownMenu
                  title="Pour maman"
                  url={"/categorie-produit/pour-maman"}
                  isMobileShow={mobileShown}
                >
                  {subCategories["pour-maman"].map((c) => (
                    <Link key={c.url} className="dropdown-item" to={c.url}>
                      {c.title}
                    </Link>
                  ))}
                </DropdownMenu>
              </li>
              <li>
                <DropdownMenu
                  title="Pour bébé et enfants"
                  url={"/categorie-produit/pour-bebe-et-enfant"}
                  isMobileShow={mobileShown}
                >
                  {subCategories["pour-bebe-et-enfant"].map((c) => (
                    <Link key={c.url} className="dropdown-item" to={c.url}>
                      {c.title}
                    </Link>
                  ))}
                </DropdownMenu>
              </li>
              <li>
                <DropdownMenu
                  title="Pour la maison"
                  url={"/categorie-produit/pour-la-maison"}
                  isMobileShow={mobileShown}
                >
                  {subCategories["pour-la-maison"].map((c) => (
                    <Link key={c.url} className="dropdown-item" to={c.url}>
                      {c.title}
                    </Link>
                  ))}
                </DropdownMenu>
              </li>
              <li>
                <DropdownMenu
                  title="Pour papa"
                  url={"/categorie-produit/pour-papa"}
                  isMobileShow={mobileShown}
                >
                  {subCategories["pour-papa"].map((c) => (
                    <Link key={c.url} className="dropdown-item" to={c.url}>
                      {c.title}
                    </Link>
                  ))}
                </DropdownMenu>
              </li>
              {/* <li><Link to="/categorie-produit/precommande/">Précommande</Link></li> */}
              <li>
                <Link to="/galerie">Galerie</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/nous-joindre">Nous joindre</Link>
              </li>
              <li>
                <Link to="/panier">
                  <i className="fa fa-shopping-cart"></i> Panier
                  {bagQuantity > 0 && (
                    <span className="ml-1 badge badge-pill badge-primary">
                      {bagQuantity}
                    </span>
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
}

import React from "react";

export default function NotFoundPage() {
    return (
        <div className="container pageContent">
            <div className="row">
                <div className="col-12 text-center mt-5">
                    <h1><i className="fa fa-frown-o fa-4x"></i></h1>
                    <h1>Oh non! Page non trouvée.</h1>
                </div>
            </div>
        </div>
    );
}
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FormattedNumber } from "react-intl";
import LoadingIcon from "./LoadingIcon";
import { imageCropSquare } from "../helpers";
import { getCheapestVariant, getVariantCompareAtPrice, getVariantPrice } from "../PriceHelper";
import * as Constants from "../Constants";
import FavoriteLabel from "./FavoriteLabel";

export default function GridViewProduct(props) {

    let [isAdding, setIsAdding] = useState(false);
    let [showAddMessage, setShowAddMessage] = useState(false);

    const pictureUrl = imageCropSquare(props.product.images.edges[0].node, 550);
    const productUrl = "/produit/" + props.product.handle;
    
    const variant = getCheapestVariant(props.product).node;
    
    const hasMultipleVariants = props.product.variants.edges.length > 1;
    let isOutOfStock = false;

    if (!hasMultipleVariants) {
        isOutOfStock = !props.product.variants.edges[0].node.available;
    }

    function addToCart() {
        setIsAdding(true);
        props.addVariantToCart(variant.id, 1).then(() => {
            setIsAdding(false);
            setShowAddMessage(true);
        })
    }

    const needPassword = props.product.title === "Mini groupe personnalisé - Lilo et Stitch";

    const price = getVariantPrice(variant);
    const compareAtPrice = getVariantCompareAtPrice(variant);

    const isFavorite = props.product.tags && props.product.tags.map(t => t.toLowerCase()).includes(Constants.FavoriteTagName.toLowerCase());

    return (
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
            <div className="card productCard">
                <Link to={productUrl}>
                    <img src={pictureUrl} className="card-img-top" alt={props.product.images.edges[0].node.altText} />
                    {isFavorite && <FavoriteLabel />}
                </Link>
                <div className="card-body">
                    <Link className="productTitle" to={productUrl}>
                        <h5 className="card-title">{props.product.title}</h5>
                    </Link>
                    <div className="text-center">
                        <FormattedNumber value={price} style="currency" currency={"CAD"} />
                        {compareAtPrice && <span className="compareAtPrice"><FormattedNumber value={compareAtPrice} style="currency" currency={"CAD"} /></span>}
                        <div className="mt-1">
                            {hasMultipleVariants ? (
                                <Link className="btn btn-primary" to={productUrl}>Choix des options</Link>
                            ) : (
                                    <>
                                        {isOutOfStock && <Link to={productUrl} className="btn btn-secondary">Rupture d'inventaire</Link>}
                                        {!isOutOfStock && needPassword && <Link to={productUrl} className="btn btn-secondary">Voir les détails</Link>}
                                        {!isOutOfStock && !needPassword && (
                                            <>
                                                {showAddMessage && (
                                                    <div className="alert alert-info">Ajouté au <Link className="linkPrimary font-weight-bold" to="/panier">panier</Link></div>
                                                )}
                                                {!isAdding && !showAddMessage && <button type="button" className="btn btn-primary" onClick={() => addToCart()}>Ajouter au panier</button>}
                                                <LoadingIcon isLoading={isAdding} />
                                            </>
                                        )}
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
import React from "react";

import HomePage from "./HomePage";
import CategoryPage from "./CategoryPage";
import { Switch, Route, useLocation } from "react-router-dom";
import NotFoundPage from "./NotFoundPage";
import ProductDetail from "./ProductDetail";
import Cart from "./Cart";
import WarrantyPage from "./WarrantyPage";
import GalleryPage from "./GalleryPage";
import ErrorBoundary from "./ErrorBoundary";
import AlbumPage from "./AlbumPage";
import { albumProductHandle } from "../Constants";
import BlogPage from "./BlogPage";
import BlogPostPage from "./BlogPostPage";
import ContactUsPage from "./ContactUsPage";
import SearchResultPage from "./SearchResultPage";

export default function PageContent(props) {
  const location = useLocation();

  return (
    <ErrorBoundary key={location.pathname}>
      <Switch>
        <Route exact path="/">
          <HomePage
            client={props.client}
            addVariantToCart={props.addVariantToCart}
          />
        </Route>
        <Route exact path="/categorie-produit/:categoryHandle">
          <CategoryPage
            client={props.client}
            addVariantToCart={props.addVariantToCart}
          />
        </Route>
        <Route exact path={"/produit/" + albumProductHandle}>
          <AlbumPage
            client={props.client}
            checkout={props.checkout}
            addVariantToCart={props.addVariantToCart}
          />
        </Route>
        <Route exact path="/produit/:productHandle">
          <ProductDetail
            client={props.client}
            checkout={props.checkout}
            addVariantToCart={props.addVariantToCart}
          />
        </Route>
        <Route exact path="/panier">
          <Cart
            client={props.client}
            checkout={props.checkout}
            removeItemFromCart={props.removeItemFromCart}
            updateItemQuantity={props.updateItemQuantity}
            isCheckoutLoading={props.isCheckoutLoading}
            isCheckoutLoaded={props.isCheckoutLoaded}
            applyCoupon={props.applyCoupon}
            removeCoupon={props.removeCoupon}
          />
        </Route>
        <Route exact path="/garantie" component={WarrantyPage} />
        <Route exact path="/galerie">
          <GalleryPage client={props.client} />
        </Route>
        <Route exact path="/recherche">
          <SearchResultPage client={props.client} addVariantToCart={props.addVariantToCart} />
        </Route>
        <Route exact path="/blog">
          <BlogPage />
        </Route>
        <Route exact path="/blog/:slug">
          <BlogPostPage />
        </Route>
        <Route exact path="/nous-joindre">
          <ContactUsPage />
        </Route>
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </ErrorBoundary>
  );
}

import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.  
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {    // You can also log the error to an error reporting service 
    //logErrorToMyService(error, errorInfo);
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {      // You can render any custom fallback UI
      if (!this.props.hideError) {
        return <p className="m-3 alert alert-warning">Une erreur est survenue.</p>;
      } else {
        return null;
      }
    }

    return this.props.children;
  }
}
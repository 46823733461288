import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { FormattedDate } from "react-intl";
import LoadingIcon from "./LoadingIcon";
import { generatePostUrl } from "../helpers";
import { wordpressPublicApiBaseUrl } from "../Constants";
import "../wordpress.scss";

export default function BlogPostPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [post, setPost] = useState([]);

    let { slug } = useParams();

    useEffect(() => {
        async function fetchPosts() {
            setIsLoading(true);
            const result = await axios.get(wordpressPublicApiBaseUrl + "/posts/slug:" + slug);

            setIsLoading(false);
            setPost(result.data);
        }

        fetchPosts();
    }, [slug]);

    return (
        <div className="container pageContent">
            {!isLoading && (
                <>
                    <Helmet title={post.title}>
                        <link rel="canonical" href={generatePostUrl(post.slug, true)} />
                        {/* <meta name="description" content={product.description} /> */}
                        <meta property="og:title" content={post.title} />
                        {/* <meta property="og:description" content={product.description} /> */}
                        <meta property="og:type" content="article" />
                        <meta property="og:url" content={generatePostUrl(post.slug, true)} />
                        <meta property="og:site_name" content="Confections Moufette" />
                        {post.featured_image && <meta property="og:image" content={post.featured_image} />}
                        <meta property="article:published_time" content={post.date} />
                        <meta property="article:modified_time " content={post.modified} />
                    </Helmet>
                    <h1>{post.title}</h1>

                    <em><FormattedDate value={post.date} year="numeric" month="long" day="numeric" /></em>

                    <div className="wordpress-namespace mt-2" dangerouslySetInnerHTML={{ __html: post.content }}></div>

                    {slug === "confections-moufette-se-joint-a-lequipe-daux-ptits-cadeaux" && (
                        <div className="text-center pt-2">
                            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FAuxpetitscadeaux%2Fvideos%2F468797267898428%2F&show_text=true&width=560" width="560" height="429" style={{ "border": "none", "overflow": "hidden" }} scrolling="no" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen={true}></iframe>
                        </div>
                    )}
                </>
            )}

            <LoadingIcon isLoading={isLoading} />
        </div>
    );
}